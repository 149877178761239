import { Link, useLoaderData, useParams } from "react-router-dom";
import { SalesOrderHeader } from "../models/SalesOrdersDataModels";
import "./SalesOrderDetail.css";

const SalesOrderDetail = () => {
    let orderHeader = useLoaderData() as SalesOrderHeader;

    if (!orderHeader)
        throw new Error("Invalid Order");

    return (
        <div className="mb-5">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb fs-3">
                    <li className="breadcrumb-item"><Link to={".."} relative="route">Sales Orders</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{orderHeader.orderId}</li>
                </ol>
            </nav>

            <div className="accordion mt-5">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#plmOrderHeaderPanel" aria-expanded="true" aria-controls="plmOrderHeaderPanel">Header Data</button>
                    </h2>
                    <div id="plmOrderHeaderPanel" className="accordion-collapse collapse show">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-6">
                                    <p>Product Number: {orderHeader.productNumber}</p>
                                    <p>Product Family: {orderHeader.productFamily}</p>
                                    <p>Production Location: {orderHeader.productionLocation}</p>
                                    <p>Distribution Number: {orderHeader.debitor}</p>
                                    <p>Type: {orderHeader.typePrefix}</p>
                                </div>
                                <div className="col-6">
                                    <p>Assembly Line: {orderHeader.assemblyLine}</p>
                                    <p>Assembly Planning Perior: {orderHeader.assemblyPP}</p>
                                    <p>Final Assemblt Date To-Be: {orderHeader.assemblyDatePlan}</p>
                                    <p>Final Assemblt Date To-Be New: {orderHeader.assemblyDatePlanNew}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion mt-3">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#plmOrderDetailPanel" aria-expanded="true" aria-controls="plmOrderDetailPanel">Order Details</button>
                    </h2>
                    <div id="plmOrderDetailPanel" className="accordion-collapse collapse">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-6">
                                    <p>Product Number: {orderHeader.productNumber}</p>
                                    <p>Product Family: {orderHeader.productFamily}</p>
                                    <p>Production Location: {orderHeader.productionLocation}</p>
                                    <p>Distribution Number: {orderHeader.debitor}</p>
                                    <p>Type: {orderHeader.typePrefix}</p>
                                </div>
                                <div className="col-6">
                                    <p>Assembly Line: {orderHeader.assemblyLine}</p>
                                    <p>Assembly Planning Perior: {orderHeader.assemblyPP}</p>
                                    <p>Final Assemblt Date To-Be: {orderHeader.assemblyDatePlan}</p>
                                    <p>Final Assemblt Date To-Be New: {orderHeader.assemblyDatePlanNew}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SalesOrderDetail; 