const awsconfig = {
  Auth: {
    Cognito: {
      userPoolId: 'eu-central-1_86bkhLNkz',
      userPoolClientId: '3kbrd2v9j8if1djn8h8g4el9pg',
      loginWith: {
        email: true,
        phone: false,
        username: false,
      },
      mfa: {
        smsEnabled: false,
        totpEnabled: true,
      }
    }
  },
};

export default awsconfig;  