import { ChangeEvent, useState } from "react";
import "./ExportModal.css";
import { Radio, RadioGroupField } from "@aws-amplify/ui-react";

type ExportModalProps = {
    id: string;
    onExport: (configuration: ExportConfigurationProps) => void;
    hasFilters?: boolean;
};

type ExportConfigurationProps = {
    fileType: "CSV" | "TXT" | "ALL";
    includeFilters: boolean;
}

const ExportModal = ({ id, onExport, hasFilters }: ExportModalProps) => {
    const [withFilter, setWithFilter] = useState<boolean>(false);
    const [fileFormat, setFileFormat] = useState<"CSV" | "TXT" | "ALL">("ALL");

    return (
        <div className="modal fade" id={id} tabIndex={-1} aria-labelledby={id.concat("Label")} aria-hidden={true}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5">Export</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {hasFilters && <div className="alert alert-warning">Filters are applied <i className="bi bi-info-circle"></i></div>}
                        <h5>Content</h5>
                        <RadioGroupField legend="Content" name="Content" className="mb-5" legendHidden={true}>
                            <Radio value="true" onChange={() => setWithFilter(false)} defaultChecked={true}>All Existing Orders</Radio>
                            <Radio value="false" onChange={() => setWithFilter(true)} disabled={!hasFilters}>Filtered Objects</Radio>
                        </RadioGroupField>
                        <h5>File Format</h5>
                        <RadioGroupField legend="File Format" name="File Format" legendHidden={true}>
                            <Radio value="CSV" onChange={() => setFileFormat("CSV")}>CSV</Radio>
                            <Radio value="TXT" onChange={() => setFileFormat("TXT")}>TXT</Radio>
                            <Radio value="ALL" onChange={() => setFileFormat("ALL")} defaultChecked={true}>ALL</Radio>
                        </RadioGroupField>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={() => { onExport({ fileType: fileFormat, includeFilters: withFilter }); }} data-bs-dismiss="modal">Export</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ExportModal, type ExportConfigurationProps }