import { useEffect, useState } from "react";
import { DataService } from "../services/DataService";
import { SalesOrderHeader } from "../models/SalesOrdersDataModels";
import { DataTableColumnHeader, FilterableDataTable } from "./FilterableDataTable/FilterableDataTable";

type SalesOrdersProps = {
    dataService: DataService;
}

const pageTitle: string = "Sales Orders";

const columns: DataTableColumnHeader<SalesOrderHeader>[] = [
    { columnId: "orderId", name: 'Order Id' },
    { columnId: "productNumber", name: 'Product Number' },
    { columnId: "productFamily", name: 'Product Family' },
    { columnId: "productionLocation", name: 'Production Location' },
    { columnId: "debitor", name: 'Distribution Number' },
    { columnId: "typePrefix", name: 'Type' },
    { columnId: "assemblyLine", name: 'Assembly Line' },
    { columnId: "assemblyPP", name: 'Assembly Planning Period' },
    { columnId: "assemblyDatePlan", name: 'Final Assembly Date To-Be' },
    { columnId: "assemblyDatePlanNew", name: 'Final Assembly Date To-Be New' },
];

const SalesOrders = ({ dataService }: SalesOrdersProps) => {
    const [salesOrdersHeaders, setSalesOrdersHeaders] = useState<SalesOrderHeader[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getSalesOrdersHeaders = async () => {
        const salesOrdersHeaders = await dataService.getSalesOrdersHeaders();
        setSalesOrdersHeaders(salesOrdersHeaders);
        setIsLoading(false);
    }

    useEffect(() => {
        getSalesOrdersHeaders();
    }, [isLoading]);

    const onImportHandler = (json: string) => {
        const orderHeaderDataList: SalesOrderHeader[] = JSON.parse(json);

        const postSalesOrdersHeaders = async () => {
            const result = await dataService.postSalesOrdersHeaders(orderHeaderDataList);
            setIsLoading(true);
        }

        if (orderHeaderDataList.length > 0)
            postSalesOrdersHeaders();
    };

    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb fs-3">
                    <li className="breadcrumb-item active" aria-current="page">{pageTitle}</li>
                </ol>
            </nav>
            <FilterableDataTable dataset={salesOrdersHeaders} onImport={onImportHandler} isLoading={isLoading} columns={columns} />
        </div>
    );
}

export { SalesOrders }