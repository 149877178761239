import { SalesOrderHeader } from "../models/SalesOrdersDataModels";
import { AuthService } from "./AuthService";


const salesOrdersHeadersUrl = "https://fsz04e8jr2.execute-api.eu-central-1.amazonaws.com/prod/orders-headers";

const salesOrdersHeadersMockData: SalesOrderHeader[] = [{ "productFamily": "TMS", "assemblyLine": "P", "assemblyDatePlan": 20240318, "orderId": 205745189, "productNumber": "06DD172", "assemblyDatePlanNew": 20240319, "productionLocation": "C", "typePrefix": "L", "debitor": "700071" }, { "productFamily": "TMS", "assemblyLine": "P", "assemblyDatePlan": 20240518, "orderId": 703845179, "productNumber": "06KG872", "assemblyDatePlanNew": 20240519, "productionLocation": "C", "typePrefix": "L", "debitor": "006071" }, { "productFamily": "TMS", "assemblyLine": "P", "assemblyDatePlan": 20240418, "orderId": 703845178, "productNumber": "06KG869", "assemblyDatePlanNew": 20240419, "productionLocation": "C", "typePrefix": "L", "debitor": "006071" }, { "productFamily": "TMS", "assemblyLine": "M", "assemblyDatePlan": 20240622, "orderId": 505592646, "productNumber": "04R55F9", "assemblyDatePlanNew": 20240622, "productionLocation": "M", "typePrefix": "L", "assemblyPP": 202419, "debitor": "702574" }, { "productFamily": "TMS", "assemblyLine": "M", "assemblyDatePlan": 20240522, "orderId": 606892646, "productNumber": "06K33F9", "assemblyDatePlanNew": 20240522, "productionLocation": "M", "typePrefix": "L", "assemblyPP": 202418, "debitor": "402274" }, { "productFamily": "TMS", "assemblyLine": "P", "assemblyDatePlan": 20240920, "orderId": 105735165, "productNumber": "03XT172", "assemblyDatePlanNew": 20240921, "productionLocation": "M", "typePrefix": "L", "debitor": "750071" }, { "productFamily": "TMS", "assemblyLine": "M", "assemblyDatePlan": 20240702, "orderId": 813969994, "productNumber": "06KH743", "productionLocation": "M", "typePrefix": "L", "debitor": "007646" }, { "productFamily": "TMS", "assemblyLine": "M", "assemblyDatePlan": 20240522, "orderId": 606892645, "productNumber": "06K33L9", "assemblyDatePlanNew": 20240522, "productionLocation": "M", "typePrefix": "L", "assemblyPP": 202418, "debitor": "402274" }]
const isTest = false;

export class DataService {

    private authService: AuthService;
    private salesOrdersHeaders: SalesOrderHeader[] = [];

    constructor(authService: AuthService) {
        this.authService = authService;
    }

    public async getSalesOrdersHeaders(): Promise<SalesOrderHeader[]> {
        if (isTest) {
            this.salesOrdersHeaders = salesOrdersHeadersMockData;
            return this.salesOrdersHeaders; // avoiding going to backend
        }

        const authorizationToken = await this.authService.getAuthorizationToken();

        const getSalesOrdersHeadersResult = await fetch(salesOrdersHeadersUrl, {
            method: 'GET',
            headers: {
                'Authorization': authorizationToken!
            }
        });

        this.salesOrdersHeaders = await getSalesOrdersHeadersResult.json();
        return this.salesOrdersHeaders;
    }

    public async postSalesOrdersHeaders(orderHeaderDataList: SalesOrderHeader[]): Promise<boolean> {
        if (isTest)
            return true; // avoiding going to backend

        const authorizationToken = await this.authService.getAuthorizationToken();

        const postSalesOrdersHeadersResult = await fetch(salesOrdersHeadersUrl, {
            method: 'POST',
            headers: {
                'Authorization': authorizationToken!
            },
            body: JSON.stringify(orderHeaderDataList)
        });

        return postSalesOrdersHeadersResult.ok;
    }
}