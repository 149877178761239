import { ChangeEvent, useState } from "react";
import { FilterModal } from "./FilterModal";
import { DataTableColumnHeader, Sort } from "./FilterableDataTable";
import "./FilterableColumnHeader.css";

type FilterableColumnHeaderProps<T> = DataTableColumnHeader<T> & {
    onFilterChangedHandler: (evt: ChangeEvent<HTMLInputElement>) => void;
    onSortChangedHandler: (columnId: keyof T) => void;
    sort?: Sort<T>;
}

const FilterableColumn = <T,>({ columnId, name, onFilterChangedHandler, onSortChangedHandler, sort }: FilterableColumnHeaderProps<T>) => {
    const [isFiltered, setFiltered] = useState(false);
    const filterModalId = `filterModalId-${columnId.toString()}`;

    return (
        <th scope="col">
            <div className="d-flex align-items-end">
                <button type="button" className="btn d-flex text-light py-0 px-1" onClick={() => { onSortChangedHandler(columnId); }}>
                    <span className="align-self-center text-start">{name}</span>
                    <div className="d-flex flex-column align-self-end">
                        <i className={`${sort?.columnId == columnId && sort?.order == 'DESC' ? "invisible" : ""} bi bi-caret-up-fill fs-7`}></i>
                        <i className={`${sort?.columnId == columnId && sort?.order == 'ASC' ? "invisible" : ""} bi bi-caret-down-fill fs-7`}></i>
                    </div>
                </button>
                <button type="button" className="btn text-light p-1" data-bs-toggle="modal" data-bs-target={`#${filterModalId}`}>
                    <i className={`bi bi-funnel${isFiltered ? '-fill' : ''} fs-7`} />
                </button>
                <FilterModal id={filterModalId} columnName={name} onChangeHandler={(evt) => {
                    setFiltered(evt.currentTarget.value.length > 0);
                    onFilterChangedHandler(evt);
                }} />
            </div>
        </th>
    );
}

export { FilterableColumn, type FilterableColumnHeaderProps }