import { useState } from "react";
import "./ImportModal.css";

type ImportModalProps = {
    id: string;
    onImport: (json: string) => void;
};

const ImportModal = ({ id, onImport }: ImportModalProps) => {
    const [jsonText, setJSONText] = useState<string | null>(null);
    const inputFileId = "inputFileId";

    const onFileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget?.files && event.currentTarget.files.length > 0) {
            const getJSONText = async (file: File) => {
                setJSONText(await file.text());
            }
            getJSONText(event.currentTarget.files[0]);
        }
    };

    const onImportClickHandler = () => {
        if (jsonText)
            onImport(jsonText);

        clearImportModal();
    };

    const clearImportModal = () => {
        setJSONText(null);

        const fileInputElem = document.getElementById(inputFileId);
        if (fileInputElem)
            (fileInputElem as HTMLInputElement).value = "";
    };

    return (
        <div className="modal fade" id={id} tabIndex={-1} aria-labelledby={id.concat("Label")} aria-hidden={true}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5">Import</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <input type="file" multiple={false} accept="application/json" onChange={onFileChangeHandler} id={inputFileId} />
                        <pre className="text-code mt-3">
                            {jsonText}
                        </pre>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => { clearImportModal(); }}>Cancel</button>
                        <button type="button" className={`btn btn-primary ${!jsonText ? "disabled" : ""}`} onClick={onImportClickHandler} data-bs-dismiss="modal">Import</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ImportModal }