import { ChangeEvent } from "react";

type FilterModalProps = {
    id: string;
    columnName: string;
    onChangeHandler: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const FilterModal = ({ id, columnName, onChangeHandler }: FilterModalProps) => {

    return (
        <div className="modal fade" id={id} tabIndex={-1} aria-labelledby={id.concat("Label")} aria-hidden={true}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <span className="modal-title text-dark">{columnName} <i className="bi bi-funnel-fill" /></span>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <span className="w-100">
                            <input className="w-100" type="text" multiple={false} onChange={onChangeHandler} />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { FilterModal }