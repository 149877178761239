import { Amplify } from 'aws-amplify';
import { Authenticator, Image, View, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import { isEmailDomainValid } from './validations/EmailValidator';
import { DataService } from './services/DataService';
import { AuthService } from './services/AuthService';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import './App.css';
import logo from "./assets/images/logo.png";
import { SalesOrders } from './components/SalesOrders';
import { APP_TITLE } from './properties';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import SalesOrderDetail from './components/SalesOrderDetail';
import ErrorPage from './components/ErrorPage';
import SalesOrderDetailError from './components/SalesOrderDetailError';

Amplify.configure(awsconfig);

const authService = new AuthService();
const dataService = new DataService(authService);

function App() {
  const components = {
    Header() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="MAN logo" src={logo} />
        </View>
      );
    },
  };

  const router = createHashRouter(
    [
      {
        path: "/",
        element: <SalesOrders dataService={dataService} />,
        errorElement: <ErrorPage />
      },
      {
        path: "orders/:id",
        element: <SalesOrderDetail />,
        loader: async ({ params }) => {
          let orderDetail = (await dataService.getSalesOrdersHeaders()).filter((orderHeader) => { return orderHeader.orderId.toString() == params.id }).pop();

          return orderDetail;
        },
        errorElement: <SalesOrderDetailError />
      }
    ]
  );

  return (
    <Authenticator components={components} services={{
      async validateCustomSignUp(formData) {
        if (!isEmailDomainValid(formData.email))
          return { email: 'Email domain is not valid!', };
        return null;
      },
    }} className='lf-amplify authenticator' >
      {({ signOut, user }) => (
        <>
          <header>
            <nav className="navbar navbar-expand-lg bg-body">
              <div className="container">
                <a href="" className="navbar-brand fw-bold">{APP_TITLE}</a>
                <button className="navbar-toggler" type='button' data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                <button className="btn btn-primary fw-bold" onClick={signOut}>Sign out</button>
              </div>
            </nav>
          </header>
          <section className='container mt-5'>
            <RouterProvider router={router} />
          </section>
        </>
      )}
    </Authenticator>
  );
}

export default App;
