import { ErrorResponse, isRouteErrorResponse, Link, useResolvedPath, useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <h3>Oops!</h3>
            <br />
            <p>
                An error ocurred: {(isRouteErrorResponse(error)) ? error.statusText : "An error occurred."}
            </p>
            <p>
                <Link to={".."}>Home</Link>
            </p>
        </div>
    );
}