import { ImportModal } from "./ImportModal";
import "./ActionBar.css";
import { ExportModal, type ExportConfigurationProps } from "./ExportModal";

type ActionBarProps = {
    onImport: (json: string) => void;
    onExport: (configuration: ExportConfigurationProps) => void;
    isLoading: boolean;
    hasFilters?: boolean;
}

export const ActionBar = ({ onImport, isLoading, onExport, hasFilters }: ActionBarProps) => {
    const importModalId = "importUploadModal";
    const exportModalId = "exportUploadModal";

    return (
        <div className="row mt-5 mb-3">
            <div className="col-6">
                <div className={`${!isLoading ? "d-none" : ""} spinner-border text-primary`} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <div className="col-6 d-flex justify-content-end">
                <button type="button" className="btn btn-secondary mb-3 d-none">
                    <svg
                        fill="#000000"
                        viewBox="0 0 32 32"
                        id="icon"
                        xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <defs>
                                <style> .cls-1 &#123;fill: none; &#125; </style>
                            </defs>
                            <path d="M22.5,9A7.4522,7.4522,0,0,0,16,12.792V8H14v8h8V14H17.6167A5.4941,5.4941,0,1,1,22.5,22H22v2h.5a7.5,7.5,0,0,0,0-15Z"></path>
                            <path d="M26,6H4V9.171l7.4142,7.4143L12,17.171V26h4V24h2v2a2,2,0,0,1-2,2H12a2,2,0,0,1-2-2V18L2.5858,10.5853A2,2,0,0,1,2,9.171V6A2,2,0,0,1,4,4H26Z"></path>
                            <rect
                                id="_Transparent_Rectangle_"
                                data-name="<Transparent Rectangle>"
                                className="cls-1"
                                width="32"
                                height="32">
                            </rect>
                        </g>
                    </svg>
                </button>
                <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target={`#${importModalId}`}>Import</button>
                <ImportModal id={importModalId} onImport={onImport} />
                <button type="button" className="btn btn-secondary ms-3" data-bs-toggle="modal" data-bs-target={`#${exportModalId}`}>Export</button>
                <ExportModal id={exportModalId} onExport={onExport} hasFilters={hasFilters} />
            </div>
        </div>
    );
}


export { type ExportConfigurationProps }