import { isRouteErrorResponse, Link, useParams, useRouteError } from "react-router-dom";

export default function SalesOrderInvalid() {
    const { id } = useParams();

    return (
        <div>
            <h3>Invalid Sales Order ID "{id}".</h3>
            <br />
            <p>Go back to <Link to={".."}>Sales Orders</Link></p>
        </div>
    );
}