/**
 * Checks if a given email domain is valid at the company's context.
 * 
 * @param email - Email with the domain to be validated.
 * @returns true if the email domain is valid, false otherwise.
 */
function isEmailDomainValid(email: string): boolean {
    // TODO: This domain white list should come dynamically from the stack.
    const domainWhiteList = new Set(['man.eu', 'digitalhub.man']);
    const emailDomain = email.split('@')[1];

    if (!domainWhiteList.has(emailDomain))
        return false;

    return true;
}

export {
    isEmailDomainValid,
}
