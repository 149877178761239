import "./FilterableDataTable.css";
import { ActionBar, type ExportConfigurationProps } from "./ActionBar";
import { FilterableColumn } from "./FilterableColumnHeader";
import { ChangeEvent, useState } from "react";
import JSZip from "jszip";
import { Link } from "react-router-dom";


type FilterableDataTableProps<T> = {
    dataset: T[];
    onImport: (json: string) => void;
    isLoading: boolean;
    columns: DataTableColumnHeader<T>[];
}

type DataTableColumnHeader<T> = {
    columnId: keyof T;
    name: string;
    sort?: Sort<T>;
}

type Sort<T> = {
    columnId: keyof T;
    order: "ASC" | "DESC" | undefined;
}

const downloadFile = (blob: Blob, filename: string) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
}

const FilterableDataTable = <T,>({ dataset, onImport, isLoading, columns }: FilterableDataTableProps<T>) => {
    const [filters, setFilters] = useState({});
    const [sort, setSort] = useState<Sort<T>>();


    const onFilterChangedHandler = (evt: ChangeEvent<HTMLInputElement>, columnId: keyof T) => {
        setFilters({ ...filters, [columnId]: evt.currentTarget.value });
    };

    const onSortChangedHandler = (columnId: keyof T) => {
        if (sort?.columnId == columnId)
            setSort({ columnId, order: sort.order == 'ASC' ? 'DESC' : 'ASC' });
        else setSort({ columnId, order: 'DESC' });
    };

    const getAppliedFilterCount = () => {
        let count: number = 0;
        Object.keys(filters).forEach((key) => count += ('' == filters[key as keyof typeof filters] ? 0 : 1));
        return count;
    }

    const onExportHandler = (configuration: ExportConfigurationProps) => {
        const csvSeparator = ";";
        const spaceSeparator = "_";
        const csvType = 'text/csv;charset=utf-8;'
        const txtType = 'text/plain;charset=utf-8;'
        const zipType = "application/zip, application/octet-stream;";
        const csvContent = buildContent(configuration.includeFilters);
        let blob: Blob;
        const filename = `Export_${Date.now()}`;

        switch (configuration.fileType) {
            case 'TXT':
                downloadFile(new Blob([csvContent], { type: txtType }), filename + ".txt");
                break;
            case 'CSV':
                downloadFile(new Blob([csvContent], { type: csvType }), filename + ".csv");
                break;
            case "ALL":
                const zip = new JSZip();
                zip.file(filename + ".txt", csvContent).file(filename + ".csv", csvContent).generateAsync({ type: "blob" }).then((blob: Blob) => {
                    downloadFile(blob, filename + ".zip");
                });
                break;
        }
    };

    const buildContent = (includeFilters: boolean) => {
        const separator = ';';
        const emptyChar = "_";
        const finalDataset = includeFilters ? getFilteredDataset() : dataset;
        const contentHeader = columns.map(header => header.name).join(separator);
        const contentBody = finalDataset.map(row => columns.map(header => {
            const rowValue = row[header.columnId];
            if (rowValue == undefined || rowValue == null || rowValue == '')
                return emptyChar;
            return rowValue;
        }).join(separator)).join("\n");
        const content = contentHeader + "\n" + contentBody;
        return content;
    };

    const getFilteredDataset = () => {
        return dataset.filter((row) => {
            return Object.keys(filters).every((key) => {
                const filterValue = filters[key as keyof typeof filters];
                const rowValue = row[key as keyof T];
                return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());
            });
        })
    };

    return (
        <div>
            <ActionBar onImport={onImport} isLoading={isLoading} onExport={onExportHandler} hasFilters={getAppliedFilterCount() > 0} />
            <div className="overflow-x-auto">
                <table className="table">
                    <thead className="table-primary">
                        <tr>
                            <th scope="col"></th>
                            {columns.map((column) => (
                                <FilterableColumn
                                    key={column.columnId.toString()}
                                    columnId={column.columnId}
                                    name={column.name}
                                    onFilterChangedHandler={(evt) => { onFilterChangedHandler(evt, column.columnId as keyof T) }}
                                    onSortChangedHandler={onSortChangedHandler} sort={sort} />
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {getFilteredDataset().sort((a, b) => {
                            if (sort == undefined)
                                return 0;
                            if (sort.order == 'ASC')
                                return a[sort.columnId] > b[sort.columnId] ? 1 : -1;

                            else return a[sort.columnId] > b[sort.columnId] ? -1 : 1;
                        }).map((row, index) => (
                            <tr key={index}>
                                <td><Link to={`orders/${row["orderId" as keyof T]}`} className="btn py-0" type="button"><i className="bi bi-eye-fill"></i></Link></td>
                                {columns.map((column) => {
                                    return <td key={column.columnId.toString()}><>{row[column.columnId]}</></td>;
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div >
    );
}

export { FilterableDataTable, type DataTableColumnHeader, type Sort };